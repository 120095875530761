import "./orderComplete.css";
import * as constants from "../constants/selConstants"

function orderComplete(props: any) {
    return (
        <section className="successSessionID-section">
            <div className="success-form">
                <div className="selorder-loading-message custom-font-size">
                    {constants.ORDER_COMPLETE_MESSAGE}
                </div>

            </div>
        </section>
    );
}

export default orderComplete;