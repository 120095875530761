
import { configuration } from "../configuration";
import axios from "axios";
const generateHeader = (token: any) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    }
}


export const Getlocalizebyaddress = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/localizeByAddress', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const Getcontent = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/getContent', { ...props.apiBody }, config).then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const Getproposal = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/proposals', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const Getpresenteligibleoffers = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/presenteligibleoffers', { ...props.apiBody }, config).then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const Getcustomers = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/customers', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}


export const Getprocessoffersincart = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/processoffersincart', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const GetSubmitOrder = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/SubmitOrder', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const GetConfigureoffersincart = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/Configureoffersincart', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const Getvalidateandreviewcart = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/validateandreviewcart', { ...props.apiBody }, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}



export const GetOrderStatus = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.get(configuration.API.HOST + '/OrderStatus?sessionID=' + props.sessionId, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}

export const GetverifyAgentProfile = async (props: any) => {
    let response: any;
    const config = generateHeader(props.token)
    await axios.post(configuration.API.HOST + '/verifyAgentProfile', props.apiBody, config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}


export const Getsessions = async (props: any) => {
    let response: any;
    await axios.get(configuration.API.HOST + '/sessions/' + props.amdocsId, props.config)
        .then(res => { response = res; })
        .catch((error) => {
            response = error.response
        })
    return response;
}





