import { useNavigate, useLocation } from "react-router-dom";
import "./selOffer.css";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function SelOffer() {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const content: [any] = state.content;
  const shoppingOffer: [any] = state.shoppingOffer ? state.shoppingOffer : [];
  const configureOffer = (offerId : string) => {
    Navigate("/SelConfigure",{state : {offerId : offerId}});
  }
  const getName = (offerId : string) => {
    try{
      var elements : any[] = content.filter((value) => value.id === "OFFER:"+offerId)[0]
        .presentationContents.presentationContent;
      return elements.filter((value) => value.name === "Name")[0].content;
    }
    catch(ex){
      Navigate("/Error");
    }
  }
  const getPromo = (offerId : string) => {
    try{
      var elements : any[] = content.filter((value) => value.id === "OFFER:"+offerId)[0]
        .presentationContents.presentationContent;
      return elements.filter((value) => value.name === "ShortPromoCodeText")[0].content;
    }
    catch(ex){
      Navigate("/Error");
    }
  }
  const getDescription = (offerId : string) => {
    try{
      var elements : any[] = content.filter((value) => value.id === "OFFER:"+offerId)[0]
        .presentationContents.presentationContent;
      var description = elements.filter((value) => value.name === "PriceDurationText")[0].content;
      return description ? description : "for 12 months with 2-year agreement when you enroll in Auto Pay and Paperless Billing - $89.99/mo without enrollment.";
    }
    catch(ex){
      Navigate("/Error");
    }
  }

  return (
    <section className="sel-offer">
      {shoppingOffer.map((i) => {
        return (<div key={i.offerId ? i.offerId : 1} className="offer-card-div">
          <div className="offer-viewDiv">
            <div className="offer-viewHeading">
              <span><br/></span>
            </div>
            <div className="offer-card">
              <h3 className="offer-card-name" style={{ minHeight: "22px" }}>
                <span>{ getName(i.offerId) }</span>
              </h3>
              <div className="offer-card-price">${i.monthlyCharges ? i.monthlyCharges : 79.99}/mo</div> 
              <div aria-hidden="true" style={{ minHeight: "20px" }}></div>
              <div className="offer-card-price-disclaimer">
                {getDescription(i.offerId)}
              </div>
              <div aria-hidden="true" style={{ minHeight: "20px" }}></div>
              <div className="offer-card-price-calls-to-action">
                <button className="button button-primary" type="button" onClick={() => configureOffer(i.offerId)}>
                    Order Now
                </button>
                <div className="offer-anchor-dev">
                    VIEW COSTS &amp; DETAILS
                </div>
              </div>
              <ul className="offer-card-feature-list">
                <li className="offer-card-feature-list-item" data-tracking="">
                  <span className="offer-card-feature-list-item-image">
                    <CircularProgressbar 
                      strokeWidth={10}
                        value={i.dynamicParameters && i.dynamicParameters.nVPair && i.dynamicParameters.nVPair[1] 
                          ? ( i.dynamicParameters.nVPair[1].value.split(' ')[1] === "Gbps" 
                          ? 1000 : i.dynamicParameters.nVPair[1].value.split(' ')[0]) : "0"} 
                        maxValue={1500} 
                        text={`${i.dynamicParameters && i.dynamicParameters.nVPair && i.dynamicParameters.nVPair[1] 
                          ? (i.dynamicParameters.nVPair[1].value.split(' ')[1] === "Gbps" 
                          ? i.dynamicParameters.nVPair[1].value.split(' ')[0] + ' Gig' : i.dynamicParameters.nVPair[1].value.split(' ')[0]) : "0"}`}
                        styles={buildStyles({textSize: '25px',  backgroundColor: 'rgb(245, 193, 0)',
                        rotation: 1.5 / 2, pathColor: "rgb(245, 193, 0)", textColor: '#000000'})}
                    />
                  </span>
                  <span className="offer-card-feature-list-item-description">
                    { getPromo(i.offerId) }
                  </span>
                </li>
                <li data-tracking="">
                  <span className="offer-card-feature-list-item-image">
                    <br/>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        );
      })}
    </section>
  );
}

export default SelOffer;
