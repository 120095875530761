import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './SelCheckout.css';
import CircularProgress from '@mui/material/CircularProgress';
import * as constants from '../../constants/selConstants';
import Checkbox from '@mui/material/Checkbox';
import "react-datepicker/dist/react-datepicker.css";

import { GetConfigureoffersincart, Getvalidateandreviewcart, Getcontent } from "../../constants/services";

function SelCheckout() {
    const Navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState<any>(null);
    const [configDetails, setConfigDetails] = useState<any>(null);
    const [personDetails, setPersonDetails] = useState<any[]>([]);  
    const [loading, setLoading] = useState(true);
    const { state } = useLocation();
    const processedResponse = state.processedResponse;
    const [checkboxchecked, setcheckboxchecked] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("accesstoken")
        const configureOffers = async () => {
            const apiBody = {
                SessionId: localStorage.getItem("sessionId"),
                SelectedExistingItems: null,
                SelectedShoppingElements: {
                    SelectedShoppingElement: [
                        {
                            PriceReferenceId: processedResponse.PriceReferenceIds[0],
                            OfferId: processedResponse.OfferId,
                            Quantity: 1,
                            GroupSequence: 1,
                            Configurations: null
                        },
                        {
                            PriceReferenceId: processedResponse.PriceReferenceIds[1],
                            OfferId: processedResponse.OfferId,
                            Quantity: 1,
                            GroupSequence: 1,
                            Configurations: null
                        }
                    ]
                },
                ServiceRequestType: null,
                SelectedUpSells: null,
                SelectedSSGConfigurations: null
            };

            const response = await GetConfigureoffersincart({ apiBody, token })
            if (response) {
                if (response != null && response.data.cart != null) {
                    validateAndReviewCart();
                    setOrderDetails({
                        monthlyCharge: response.data.cart.offerCartItems.offerCartItem[0].cartItem ?
                            response.data.cart.offerCartItems.offerCartItem[0].cartItem.chargeDetails.monthlyCharges : 0.00,
                        //data.cart.offerCartItems.offerCartItem[0].cartItem.monthlyCharges : 0.00,
                        equipmentFee: response.data.cart.offerCartItems.offerCartItem[0].cartGroups ?
                            response.data.cart.offerCartItems.offerCartItem[0].cartGroups.cartGroup[0]
                                .deviceCartItems.deviceCartItem[0].chargeDetails.monthlyCharges : 0.00,
                        totalMonthlyCharges: response.data.cart.totalMonthlyCharges,
                        oneTimeCharge: response.data.cart.offerCartItems.offerCartItem[0].cartGroups ?
                            response.data.cart.offerCartItems.offerCartItem[0].cartGroups.cartGroup[0]
                                .installCartItems.installCartItem[0].chargeDetails.oneTimeCharges : 0.00,
                        oneTimeTotal: response.data.cart.totalOneTimeCharges
                    });
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }

        }
        const validateAndReviewCart = async () => {
            var apiBody = {
                sessionId: localStorage.getItem("sessionId"),
                houseDebtOverrideOption: null,
                serviceRequestType: null,
                installationDetails: null
            };
            const response = await Getvalidateandreviewcart({ apiBody, token })
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    var addressDetails = response.data.serviceAddress;
                    var billingAddress = response.data.billingAddress;
                    var contactInfo = response.data.customerInfo.contact;
                    setPersonDetails([
                        {
                            heading: "Name",
                            label: contactInfo.firstName + ' ' + contactInfo.lastName,
                        }, {
                            heading: "Company",
                            label: response.data.customerInfo.organization.businessName,
                        }, {
                            heading: "Service Address",
                            label: addressDetails.streetAddress1,
                            subLine: addressDetails.city + ', ' + addressDetails.state + ' ' + addressDetails.zipCode,
                        }, {
                            heading: "Billing Address",
                            label: billingAddress.streetAddress1,
                            subLine: billingAddress.city + ', ' + billingAddress.state + ' ' + billingAddress.zipCode,
                        }, {
                            heading: "Business Phone Number",
                            label: contactInfo.telephoneNumber,
                        }, {
                            heading: "Email Address",
                            label: contactInfo.emailAddress,
                        }]);
                    setLoading(false);

                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }

        }
        const getContent = async () => {
            var apiBody = {
                affiliateId: "DOT_COM_COMMERCIAL",
                channelName: "WEB",
                ids: {
                    string: ["OFFER:" + processedResponse.OfferId],
                    stringLength: 1
                }
            }

            const response = await Getcontent({ apiBody, token })
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    var content: any[] = response.data.presentationElements.presentationElement[0]
                        .presentationContents.presentationContent;
                    setConfigDetails({
                        priceDurationDisclaimer: content.filter((value) => value.name === "PriceDurationDisclaimer")[0].content,
                        name: content.filter((value) => value.name === "Name")[0].content,
                        priceDurationText: content.filter((value) => value.name === "PriceDurationText")[0].content
                    });
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }

        }
        setTimeout(() => {
            configureOffers();
        }, 300);
        getContent();
    }, [Navigate, processedResponse]);

    const handleCheckboxClick = (event: any) => {
        setcheckboxchecked(!checkboxchecked)
    }
    const handleCompleteOrder = () => {
        Navigate("/Selorderconfirm", { state: { orderDetails, processedResponse, configDetails } });
    }

    return (
        <section className="Selcheckout">
            {loading ?
                <div>
                    <div className="selorder-loading-message">
                        <div>Please wait, we're preparing your order summary.</div>
                    </div>
                    <div className="CircularProgressBar"><CircularProgress /></div>
                </div>
                :
                <div>
                    <div className="Selcheckout-header">
                        <div className="select-option-div">
                            Checkout
                        </div>
                        <div className="Selcheckout-address-column">
                            <div className="Selcheckout-address-container">
                                <div className="Selcheckout-address"><b>YOUR SERVICE ADDRESS</b></div>
                                <div className="Selcheckout-address-content">
                                    {personDetails.filter((data) => data.heading === "Service Address")[0].label}
                                    <div className="tooltip Selcheckout-address-content">
                                        {personDetails.filter((data) => data.heading === "Service Address")[0].subLine}
                                    </div>
                                    <div className="anchor-div" style={{ marginTop: '10px', fontWeight: 700 }}>
                                        CHANGE ADDRESS
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Selcheckout-accordian-section">
                        <div className="accordian-section">
                            <div className="first-accordian">
                                <div className="first-accordian-heading">
                                    <div style={{ width: '50%' }}>
                                        1. PROVIDE ACCOUNT INFORMATION
                                    </div>
                                    <div className="anchor-div" style={{ width: '50%', textAlign: 'end' }}>
                                        CHANGE
                                    </div>
                                </div>
                                <div id="first-accordian-form" className="first-accordian-form">
                                    <div className="first-accordian-Name-form" >
                                        {personDetails && personDetails.map((i) => {
                                            return (
                                                <div className="first-accordian-Name-form-div">
                                                    <div className="first-accordian-Name-form-div-heading">{i.heading ? i.heading : ""}</div>
                                                    <div className="first-accordian-Name-form-div-label" >
                                                        {i.label ? i.label : ""}
                                                        {i.subLine ? <div className="subLine"> {i.subLine} </div> : <div></div>}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Selcheckout-accordian-section">
                        <div className="accordian-section">
                            <div className="second-accordian">
                                <div className="second-accordian-heading">
                                    2. REVIEW AND COMPLETE ORDER
                                </div>
                                <div id="second-accordian-form" className="second-accordian-form">
                                    <div className="second-accordian-Name-form-heading" style={{ padding: "10px 0px" }}>
                                        MONTHLY CHARGES
                                    </div>
                                    <table className="second-accordian-amount-overview">
                                        <tr className="second-accordian-form-business-subheading">
                                            <td>{configDetails.name}</td>
                                        </tr>
                                        <tr className="second-accordian-form-business-description" >
                                            <td>{configDetails.priceDurationText}</td>
                                            <td className="second-accordian-Name-form-div-label">${orderDetails.monthlyCharge}</td>
                                        </tr>
                                    </table>
                                    <table className="second-accordian-internetAmount-overview">
                                        <tr className="second-accordian-form-business-subheading">
                                            <td>INTERNET</td>
                                        </tr>
                                        <tr className="second-accordian-Name-form-div" style={{ marginTop: "10px" }}>
                                            <td className="second-accordian-Name-form-div-heading" >{configDetails.name}</td>
                                            <td className="second-accordian-Name-form-div-label">Included</td>
                                        </tr>
                                        <tr className="second-accordian-Name-form-div">
                                            <td className="second-accordian-Name-form-div-heading">
                                                {processedResponse.DeviceContent}
                                            </td>
                                            <td className="second-accordian-Name-form-div-label">
                                                ${orderDetails.equipmentFee}
                                            </td>
                                        </tr>
                                    </table>
                                    <table style={{ width: "100%" }}>
                                        <tr className="second-accordian-Name-form-div">
                                            <td className="second-accordian-Monthly-charges-label">Monthly charges total*</td>
                                            <td className="second-accordian-Monthly-charges-amount"><b>${orderDetails.totalMonthlyCharges}</b></td>
                                        </tr>
                                    </table>
                                    <table className="second-accordian-internetAmount-overview" style={{ marginTop: "15px" }}>
                                        <tr className="second-accordian-form-business-subheading">
                                            <td>ONE-TIME CHARGES</td>
                                        </tr>
                                        <tr className="second-accordian-charges-label">
                                            <td> This charge will only appear on your first bill.</td>
                                        </tr>

                                        <tr className="second-accordian-Name-form-div" style={{ marginTop: "10px" }}>
                                            <td className="second-accordian-Name-form-div-heading" >{processedResponse.InstallationContent}</td>
                                            <td className="second-accordian-Name-form-div-label">${orderDetails.oneTimeCharge}
                                            </td>
                                        </tr>
                                        <tr className="second-accordian-Name-form-div"  >
                                            <td className="second-accordian-form-business-subheading" style={{ width: "50%" }} >
                                                One-Time Charges Total</td>
                                            <td className="second-accordian-Name-form-div-label" style={{ marginTop: '20px' }}><b>${orderDetails.oneTimeTotal}</b>
                                            </td>
                                        </tr>

                                    </table>

                                    <p style={{ fontSize: "14px", margin: "20px 0px" }}>
                                        {configDetails.priceDurationDisclaimer}
                                    </p>
                                    <h3 className="second-accordian-form-subHeading">Terms & Conditions</h3>
                                    <div className="checkout-termscondition">The Comcast Business Service Order Agreement (“Agreement”) summarized above, which will be sent to you by email upon your online acceptance of these terms, sets forth the terms and conditions under which Comcast Cable Communications Management, LLC and its operating affiliates ("Comcast") will provide the Services to Customer.The Agreement consists of the attached document ("SOA"), the standard Comcast Business Customer Terms and Conditions ("Terms and Conditions") located at <a href="http://business.comcast.com/terms-conditions-smb">http://business.comcast.com/terms-conditions-smb</a> (or any successor URL), and any other Service Orders entered under this SOA. In the event of inconsistency among these documents, precedence will be as follows: (1) Terms and Conditions, (3) the SOA, and (4) any other Service Orders entered under this SOA. This Agreement shall commence and become a legally binding agreement upon Customer's online acceptance of the SOA. The Agreement shall terminate as set forth in the Terms and Conditions. All capitalized terms not defined in this SOA shall reflect the definitions given to them in the Terms and Conditions. Use of the Services is also subject to the Business Acceptable Use Policy located at <a href="http://business.comcast.com/customer-notifications/acceptable-use-policy">http://business.comcast.com/customer-notifications/acceptable-use-policy</a> (or any successor URL), and the Business Privacy Policy located at <a href="http://business.comcast.com/customer-notifications/customer-privacy-statement">http://business.comcast.com/customer-notifications/customer-privacy-statement</a> (or any successor URL), both of which Comcast may update from time to time and become effective upon posting.</div>
                                    <div style={{
                                        marginTop: "10px", fontSize: "14px",
                                        display: "flex", marginLeft: "-12px"
                                    }}>
                                        <Checkbox onClick={handleCheckboxClick} checked={checkboxchecked} />
                                        <div style={{ paddingTop: "15px", paddingLeft: "10px" }}>  I CONFIRM THAT I AM AUTHORIZED TO MAKE THIS PURCHASE AND AGREE TO THE TERMS & CONDITIONS.
                                        </div>
                                    </div>
                                    <div className="addresscontinuebutton-Div">
                                        <button className="accordian-complete-button" onClick={handleCompleteOrder} disabled={!checkboxchecked} > COMPLETE ORDER </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section >
    );
}

export default SelCheckout;