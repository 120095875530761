import { Configuration, PopupRequest } from "@azure/msal-browser";
import authenticationConstants from "./constants/authentication";

const authority = `https://login.microsoftonline.com/${authenticationConstants.AZURE_TENANT}`;

export const scopes = [
  authenticationConstants.PLATFORM_SCOPE,
  "email",
  "openid",
  "offline_access",
];

export const msalConfig: Configuration = {
  auth: {
    authority: authority,
    clientId: authenticationConstants.AZURE_CLIENT_ID,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest: PopupRequest = {
  scopes: scopes,
};
