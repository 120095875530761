import { PublicClientApplication } from "@azure/msal-browser";
import { scopes } from "../authConfig";

export const acquireAccessToken = async (
  msalInstance: PublicClientApplication
) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error("User authentication has failed.");
  }

  const request = {
    scopes: scopes,
    account: activeAccount || accounts[0],
  };

  return msalInstance
    .acquireTokenSilent(request)
    .then((accessTokenResponse) => accessTokenResponse.accessToken)
    .catch(() => {
      msalInstance
        .acquireTokenPopup(request)
        .then((accessTokenResponse) => accessTokenResponse.accessToken)
        .catch((error) => console.error(error));
    });
};
