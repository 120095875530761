import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./selConfigure.css";
import CircularProgress from '@mui/material/CircularProgress';
import * as constants from '../constants/selConstants';
import { Getcontent, Getprocessoffersincart } from '../constants/services';

function SelConfigure() {
    const Navigate = useNavigate();
    const { state } = useLocation();
    const offerId: [any] = state.offerId;
    const sessionId = localStorage.getItem("sessionId");
    const [loading, setLoading] = useState(true);
    const [installationContent, setInstallationContent] = useState<any[]>([]);
    const [deviceContent, setDeviceContent] = useState<any[]>([]);
    const [processedResponse, setProcessedResponse] = useState<any>(null);
    const [configDetails, setConfigDetails] = useState(
        {
            installationCharge: "",
            deviceMonthlyCharge: ""
        });

    useEffect(() => {
        const token = localStorage.getItem("accesstoken");
        const apiBody = {
            "sessionId": sessionId,
            "offerSelections": {
                "offerSelection": [
                    {
                        "actionType": "ADD",
                        "offerId": offerId
                    }
                ]
            },
            "updateType": "REPLACE",
            "serviceRequestType": null
        };
        const processOffers = async () => {
            const token = localStorage.getItem("accesstoken");
            const response = await Getprocessoffersincart({ apiBody, token })
            console.log(response)
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    var installDetails = response.data.cart.offerCartItems.offerCartItem[0].shoppingOfferDetail
                        .shoppingServiceGroups.shoppingServiceGroup[0].installs.install[0];
                    var deviceDetails = response.data.cart.offerCartItems.offerCartItem[0].shoppingOfferDetail
                        .shoppingServiceGroups.shoppingServiceGroup[0].devices.device[0]
                        .choice.shoppingElements.shoppingElement[0];
                    setConfigDetails(prevState => ({
                        ...prevState,
                        installationCharge: installDetails
                            .productPrice.chargeDetails.oneTimeCharges,
                        deviceMonthlyCharge: deviceDetails
                            .productPrice.chargeDetails.monthlyCharges
                    }));
                    var PriceReferenceIds = [installDetails.productPrice.priceReferenceId,
                    deviceDetails.productPrice.priceReferenceId];
                    getContent([installDetails.presentationElement.presentationElement[0].id,
                    deviceDetails.presentationElement.presentationElement[0].id],
                        PriceReferenceIds);
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }

                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }


        }
        const getContent = async (offers: any[], PriceReferenceIds: any[]) => {
            var apiBody = {
                affiliateId: "DOT_COM_COMMERCIAL",
                channelName: "WEB",
                ids: {
                    string: offers,
                    stringLength: offers.length
                }
            }
            const response = await Getcontent({ apiBody, token })

            try {
                if (response) {
                    if (response.status === 200 || response.status === 201) {
                        var element: any[] = response.data.presentationElements.presentationElement;
                        var installationContent: any[] = element.filter((value) => value.id === offers[0])[0]
                            .presentationContents.presentationContent;
                        var deviceContent: any[] = element.filter((value) => value.id === offers[1])[0]
                            .presentationContents.presentationContent;
                        setInstallationContent(installationContent);
                        setDeviceContent(deviceContent);
                        setProcessedResponse({
                            OfferId: offerId,
                            PriceReferenceIds: PriceReferenceIds,
                            DeviceContent: deviceContent.filter((value) => value.name === "ShortName")[0].content,
                            InstallationContent: installationContent.filter((value) => value.name === "ShortName")[0].content
                        });
                        setLoading(false);
                    }
                    else if (response.status === 500) {
                        if (response.data.Type === constants.AMDOCS) {
                            Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                        }
                        else {
                            Navigate("/Error");
                        }

                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }

            }

            catch (ex) {
                Navigate("/Error");
            }
        }
        processOffers();
    }, [Navigate, sessionId, offerId]);

    const handleClick = () => {
        Navigate("/SelCheckout", { state: { processedResponse } });
    }

    return (
        <section>
            <h2 className="heading">Select Options</h2>
            {loading ?
                <div className="CircularProgressBar"><CircularProgress /></div> :
                <div>
                    <section className="configure-section">
                        <div className="configure-form">
                            <div className="configure-div">
                                <div className="configure-segment">
                                    <h4 className="config-title">
                                        {installationContent.filter((value) => value.name === "Name")[0].content}
                                    </h4>
                                    <p>
                                        {installationContent.filter((value) => value.name === "Description")[0].content}
                                    </p>
                                    <div className="button-align">
                                        <button className="button button-background-configure" type="button">
                                            <strong>
                                                {installationContent.filter((value) => value.name === "ShortName")[0].content}
                                            </strong>
                                            <br /><span>${configDetails.installationCharge}</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="configure-segment">
                                    <div className="div-border"></div>
                                </div>
                                <div className="configure-segment">
                                    <h4 className="config-title">
                                        {deviceContent.filter((value) => value.name === "Name")[0].content}
                                    </h4>
                                    <p>
                                        {deviceContent.filter((value) => value.name === "Description")[0].content}
                                    </p>
                                    <div className="button-align">
                                        <button className="button button-background-configure" type="button">
                                            <strong>
                                                {deviceContent.filter((value) => value.name === "ShortName")[0].content}
                                            </strong>
                                            <br /><span>${configDetails.deviceMonthlyCharge}/mo</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="continuebutton-Div"><button disabled={loading} onClick={() => handleClick()} className="continuebutton-css">Continue</button></div>
                </div>
            }
        </section>
    );
}

export default SelConfigure;