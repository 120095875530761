import './selorderDetails.css';
import "react-datepicker/dist/react-datepicker.css";


function SelorderDetails() {
    const state: any = localStorage.getItem("orderDetails");
    const parsestate: any = JSON.parse(state)
    const orderDetails: any = parsestate.state.orderDetails;
    const processedResponse: any = parsestate.state.processedResponse;
    const configDetails: any = parsestate.state.configDetails;

    return (
        <section className="selorderDetails">
            {console.log(state)}
            <h1 style={{
                textAlign: "center"
            }}>Order Details</h1>
            <h5>MONTHLY CHARGES</h5>
            <table className="second-accordian-amount-overview">
                <tr className="second-accordian-form-business-subheading">
                    <td>{configDetails.name ? configDetails.name : ""}</td>
                    <td className="second-accordian-Name-form-div-label">${orderDetails.monthlyCharge}</td>
                </tr>

            </table>
            <table className="second-accordian-internetAmount-overview">
                <tr className="second-accordian-form-business-subheading">
                    <td>INTERNET</td>
                </tr>
                <tr className="second-accordian-Name-form-div" style={{ marginTop: "10px" }}>
                    <td className="second-accordian-Name-form-div-heading" >{configDetails.name}</td>
                    <td className="second-accordian-Name-form-div-label">Included</td>
                </tr>
                {/* <tr className="second-accordian-Name-form-div"  >
                    <td className="second-accordian-Name-form-div-heading" >with Auto Pay and Paperless Billing Discount</td>
                    <td className="second-accordian-Name-form-div-label">-$10.00</td>
                </tr> */}
                <tr className="second-accordian-Name-form-div">
                    <td className="second-accordian-Name-form-div-heading">{processedResponse.DeviceContent}</td>
                    <td className="second-accordian-Name-form-div-label">
                        ${orderDetails.equipmentFee}
                    </td>
                </tr>
            </table>
            <table style={{ width: "100%" }}>
                <tr className="second-accordian-Name-form-div">
                    <td className="second-accordian-Monthly-charges-label">Monthly charges total*</td>
                    <td className="second-accordian-Monthly-charges-amount"><b>${orderDetails.totalMonthlyCharges}</b></td>
                </tr>
            </table>


            <table className="second-accordian-internetAmount-overview" style={{ marginTop: "25px" }}>
                <tr className="second-accordian-form-business-subheading">
                    <td>ONE-TIME CHARGES</td>
                </tr>
                <tr className="second-accordian-charges-label" >
                    <td style={{ padding: "15px 0px" }}> This charge will only appear on your first bill.</td>
                </tr>

                <tr className="second-accordian-Name-form-div" style={{ marginTop: "10px" }}>
                    <td className="second-accordian-Name-form-div-heading" >{processedResponse.InstallationContent}</td>
                    <td className="second-accordian-Name-form-div-label">${orderDetails.oneTimeCharge}
                    </td>
                </tr>
                <tr className="second-accordian-Name-form-div"  >
                    <td className="second-accordian-form-business-subheading" style={{ width: "50%" }} >
                        One-Time Charges Total</td>
                    <td className="second-accordian-Name-form-div-label" style={{ marginTop: '20px' }}>
                        <b>${orderDetails.oneTimeTotal}</b>
                    </td>
                </tr>

            </table>

            <p style={{ fontSize: "14px", margin: "20px 0px" }}>
                {configDetails.priceDurationDisclaimer}
            </p>


        </section >
    );
}

export default SelorderDetails;