import React from "react";
import './Error.css';
import { useLocation } from "react-router-dom";
import * as constants from "../../constants/selConstants"

function Error() {
    const { state } = useLocation();
    const errorMessage =  (state != null && state.ErrorMessage != null) ? state.ErrorMessage 
        : constants.COMMON_ERROR;
    return (
        <section className="errortab">
            <div className="erroraddress-form">
                <div className="Error-message">Something went wrong!</div>
                <div className="Error-messageAdmin">{ errorMessage }</div>
            </div>
        </section>

    );
}

export default Error;
