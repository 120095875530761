import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom"
import Error from './component/Error/Error';
import Loading from './component/Loading/Loading';
export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Popup}
        errorComponent={() => <Error />}
        loadingComponent={() => <Loading />}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
