import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './selOrderconfirm.css';
import Checkbox from '@mui/material/Checkbox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { configuration } from "../configuration";
import * as constants from '../constants/selConstants';
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select';
import { GetOrderStatus, GetSubmitOrder } from '../constants/services';

function Selorderconfirm() {
    const Navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const state: any = useLocation();
    const [contactdetails, setcontactdetails] = useState<any>({});
    const [checkboxchecked, setcheckboxchecked] = useState(false);
    const question = [{
        "label": "what is your birth date",
        "value": "what is your birth date"
    }, {
        "label": "In what city were you born",
        "value": "In what city were you born"
    }, {
        "label": "What was your favorite food as a child?",
        "value": "What was your favorite food as a child?"
    }]
    const [questionValue, setStateValue] = useState<any>(null);
    useEffect(() => {
        const submitOrder = async () => {
            const token = localStorage.getItem("accesstoken");
            var apiBody = {
                sessionId: localStorage.getItem("sessionId"),
                contracts: null,
                serviceRequestType: null,
                verifications: {
                    verification: [{
                        verificationId: "string",
                        verificationText: "I am 18 years or older",
                        isVerified: true
                    }
                    ]
                },
                agentAttribute: null,
                attachment: null,
                orderAttributes: "null",
                consents: null,
                installationDetail: null
            };

            const response = await GetSubmitOrder({ apiBody, token })
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    var contact = response.data.customerInfo.contact;
                    setcontactdetails({
                        name: contact.firstName + " " + contact.lastName,
                        telephoneNumber: contact.telephoneNumber,
                        emailAddress: contact.emailAddress,
                    });
                    orderStatus();
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }


        }
        submitOrder();

        const orderStatus = async () => {
            const token = localStorage.getItem("accesstoken");
            const sessionId = localStorage.getItem("sessionId");
    
            const response = await GetOrderStatus({ sessionId, token })
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    if (response.data && response.data.status === "NOTSIGNED") {
                        setTimeout(orderStatus, configuration.POLL_WAIT_TIME_MILLI_SECONDS);
                    }
                    else if (response.data && response.data.status === "SIGNED") {
                        setloading(false);
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }
        }

    }, [Navigate]);

    const viewOrderDetails = () => {
        localStorage.setItem("orderDetails", JSON.stringify(state))
        window.open("/SelorderDetails");
    }


    const customStyles = {
        control: () => ({
            // none of react-select's styles are passed to <Control />
            border: 'none',
            display: 'flex'
        }),
        valueContainer: () => ({
            width: '100%',
            alignItems: 'center',
            display: 'grid',
        })
    }
    const handleCheckboxClick = (event: any) => {
        setcheckboxchecked(!checkboxchecked)
    }
    const handleContinue = () => {
        Navigate("/orderComplete");
    }
    return (

        <section className="selorder">
            {loading ?
                <div>
                    <div className="selorder-loading-message">
                        <div>We’re preparing your document for signature.</div>
                    </div>
                    <div className="CircularProgressBar"><CircularProgress /></div>
                </div> :
                <section>
                    <div className="selorder-header">
                        <div className="selectorder-displayaddress-headline">
                            <label className="selectorder-displayaddress-confirmation"> Thanks! we'll send your order confirmation to {contactdetails.emailAddress ? contactdetails.emailAddress : ""}</label>
                            <p className="selectorder-displayaddress-setup">Let's set up My Account</p>
                        </div>
                        <div className="selorder-address-column">
                            <div className="selorder-address-container">
                                <div className="selorder-address"><strong>We may contact you at:</strong></div>
                                <div className="selorder-address-content">
                                    {contactdetails.telephoneNumber ? contactdetails.telephoneNumber : ""}
                                    <div className="tooltip" style={{ marginTop: '5px' }}>
                                        <button className="view-order-button" onClick={() => viewOrderDetails()} >VIEW ORDER DETAILS</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="selorder-accordian-section">
                        <div className="accordian-section">
                            <h6>  Register for your account</h6>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <th className="selorder-table-heading" style={{ width: "60%" }}>Name</th>
                                    <th className="selorder-table-heading" >Email Address</th>
                                </tr>
                                <tr>
                                    <td className="selorder-table-label" style={{ width: "60%" }} > {
                                        contactdetails.name ? <span>
                                            {contactdetails.name} </span> : ""
                                    }</td>
                                    <td className="selorder-table-label"  >{contactdetails.emailAddress ? contactdetails.emailAddress : ""}</td>
                                </tr>
                            </table>
                            <div className="create-password-div">
                                <div className="textfield-div">

                                    <input className="addressInput-Box customTextfieldcss" name="city"
                                        placeholder="CREATE PASSWORD"
                                        type="password"

                                    />
                                    <input className="addressInput-Box customTextfieldcss" name="city"
                                        placeholder="CONFIRM PASSWORD"
                                        type="password"

                                    />
                                    <div className="addressInput-Box customTextfieldcss">
                                        <Select placeholder="SELECT A SECURITY QUESTION" styles={customStyles} value={questionValue} options={question}
                                            onChange={(e) => {
                                                setStateValue(e);
                                            }}
                                        />
                                    </div>
                                    <input className="addressInput-Box customTextfieldcss" name="city"
                                        placeholder="ENTER YOUR ANSWER"

                                    />




                                </div>
                                <div className="password-helpers">
                                    <h5 style={{ margin: "8px 0px" }}>PASSWORD ESSENTIALS </h5>
                                    <table>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /> </td>
                                            <td style={{ fontSize: "14px" }}>Length should be 8 to 16 characters</td>
                                        </tr>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /></td>
                                            <td style={{ fontSize: "14px" }}>At least one uppercase letter A-Z</td>
                                        </tr>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /></td>
                                            <td style={{ fontSize: "14px" }}>At least one lowercase letter a-z</td>
                                        </tr>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /></td>
                                            <td style={{ fontSize: "14px" }}>At least one number</td>
                                        </tr>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /></td>
                                            <td style={{ fontSize: "14px" }}>At least one special character</td>
                                        </tr>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /></td>
                                            <td style={{ fontSize: "14px" }}>Cannot contain your first or last name</td>
                                        </tr>
                                        <tr>
                                            <td><RemoveCircleIcon style={{ marginTop: "8px" }} /></td>
                                            <td style={{ fontSize: "14px" }}>Your password must match</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>


                            <div style={{ marginTop: "10px", fontSize: "14px" }}>
                                <Checkbox onClick={handleCheckboxClick} checked={checkboxchecked} />
                                <span>  YES, I HAVE READ AND AGREE TO <span style={{ color: "blue" }}>THE TERMS & CONDITIONS.</span>
                                </span>
                            </div>
                            <div className="addresscontinuebutton-Div"><button disabled={!checkboxchecked} onClick={handleContinue} className="addresscontinuebutton-css">Complete</button></div>
                        </div>
                    </div>

                </section >
            }
        </section >
    );
}

export default Selorderconfirm;
