import { configuration } from "../configuration";

const AZURE_TENANT = configuration.AZURE_TENANT;
const AZURE_CLIENT_ID = configuration.AZURE_CLIENT_ID;
const PLATFORM_SCOPE = configuration.PLATFORM_SCOPE;

const authenticationConstants = {
  AZURE_TENANT,
  AZURE_CLIENT_ID,
  PLATFORM_SCOPE,
};

export default authenticationConstants;
