import React from "react";
import { configuration } from "../../configuration"

import './Footer.css';

function Footer() {
    return (
        <section className="Footer">
            <div className="Footerr"> ©2021 Comcast Corporation <span style={{color:'#191919'}}> (Environment: {configuration.ENVIRONMENT}) </span></div>
        </section>

    );
}

export default Footer;
