import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SessionID.css";
import CircularProgress from '@mui/material/CircularProgress';
import * as constants from "../../constants/selConstants";
import { States } from "../../constants/USStates";
import Select from 'react-select';
import { Getlocalizebyaddress } from "../../constants/services";

function SessionID() {
    const Navigate = useNavigate();
    const [addressDetails, setaddressDetails] = useState(
        {
            streetAddress1: "",
            city: "",
            state: "",
            zipCode: "",
        });
    const [stateValue, setStateValue] = useState<any>(null);
    const states: any[] = States.States;

    const [loading, setloading] = useState(false);
    const [erroraddressDetails, seterroraddressDetails] = useState(
        {
            errorstreetAddress1: false,
            errorcity: false,
            errorstate: false,
            errorzipCode: false,
        });
    const customStyles = {
        control: () => ({
            border: 'none',
            display: 'flex'
        }),
        valueContainer: () => ({
            width: '100%',
            alignItems: 'center',
            display: 'grid',
        })
    }

    const handleFormCLick = async (e: any) => {

        const apiBody = {
            "sessionId": localStorage.getItem("sessionId"),
            "customerSearch": {
                "address": {
                    "state": stateValue != null ? stateValue.value : "",
                    "city": addressDetails.city,
                    "streetAddress1": addressDetails.streetAddress1,
                    "streetAddress2": "",
                    "zipCode": addressDetails.zipCode,
                    "unitNumber": "",
                    "unitType": ""
                }
            }
        }
        const token = localStorage.getItem("accesstoken");

        const response = await Getlocalizebyaddress({ apiBody, token })
        try {
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    Navigate("/SelCustomer")
                }
                else if (response.status === 400) {
                    Navigate("/Error", { state: { ErrorMessage: constants.ADDRESS_NOT_FOUND } })
                }
                else {
                    Navigate("/Error")
                }
            }
            else {
                Navigate("/Error")
            }
        }
        catch (e) {
            Navigate("/Error")
        }
    }
    const checkValue = (e: React.SyntheticEvent) => {
        e.preventDefault();

        setloading(true)
        if (addressDetails.streetAddress1 === "") {
            seterroraddressDetails(prevState => ({
                ...prevState,
                errorstreetAddress1: true
            }))
        }
        if (addressDetails.city === "") {
            seterroraddressDetails(prevState => ({
                ...prevState,
                errorcity: true
            }))
        }
        if (stateValue === null) {
            seterroraddressDetails(prevState => ({
                ...prevState,
                errorstate: true
            }))
        }
        if (addressDetails.zipCode === "") {
            seterroraddressDetails(prevState => ({
                ...prevState,
                errorzipCode: true
            }))
        }
        if (addressDetails.zipCode === "" || stateValue === null || addressDetails.city === "" || addressDetails.streetAddress1 === "") {

            setloading(false);
            return null;
        }
        else {

            setloading(true);
            handleFormCLick(e);

        }
    }
    return (
        <section className="SessionID-section">
            <div className="AuthorizationText">The latest Comcast deals are around the corner</div>
            <div className="SubAuthorizationText">All we need is your address to continue.</div>
            <section className="AuthorizationTab">
                <div className="address-form">
                    <Fragment>
                        <div className="address-div">
                            <div className="address-label">Street Address</div>
                            <input required id="streetAddress" className="addressInput-Box" list="language" value={addressDetails.streetAddress1}
                                type="text"
                                onChange={(e) => {
                                    setaddressDetails(prevState => ({
                                        ...prevState,
                                        streetAddress1: e.target.value
                                    }));
                                    seterroraddressDetails(prevState => ({
                                        ...prevState,
                                        errorstreetAddress1: false
                                    }))
                                }}
                                name="streetAddress1"
                            />

                            {erroraddressDetails.errorstreetAddress1 &&
                                <div className="validationError">Street Address is required field</div>
                            }
                            {/* <datalist id="language"><option>sachin</option></datalist> */}
                        </div>
                        <div className="address-div">
                            <div className="address-label">City</div>
                            <input className="addressInput-Box" name="city"
                                value={addressDetails.city}
                                type="text"
                                onChange={(e) => {
                                    setaddressDetails(prevState => ({
                                        ...prevState,
                                        city: e.target.value
                                    }));
                                    seterroraddressDetails(prevState => ({
                                        ...prevState,
                                        errorcity: false
                                    }))
                                }
                                }
                            />
                            {erroraddressDetails.errorcity &&
                                <div className="validationError">City is required field</div>
                            }

                        </div>
                        <div className="address-div">
                            <div className="address-label">State </div>
                            <div className="addressInput-Box">
                                <Select styles={customStyles} value={stateValue} options={states}
                                    onChange={(e) => {
                                        setStateValue(e);
                                        seterroraddressDetails(prevState => ({
                                            ...prevState,
                                            errorstate: false
                                        }))
                                    }}
                                />
                            </div>
                            {erroraddressDetails.errorstate &&
                                <div className="validationError">State is required field</div>
                            }

                        </div>
                        <div className="address-div">
                            <div className="address-label">Zip code </div>
                            <input className="addressInput-Box" name="zipCode" value={addressDetails.zipCode}
                                type="number"
                                onChange={(e) => {
                                    setaddressDetails(prevState => ({
                                        ...prevState,
                                        zipCode: e.target.value
                                    }))
                                    seterroraddressDetails(prevState => ({
                                        ...prevState,
                                        errorzipCode: false

                                    }))
                                }
                                }
                            />
                            {erroraddressDetails.errorzipCode &&
                                <div className="validationError">Zip code is required field</div>
                            }
                        </div>

                    </Fragment>
                </div>
            </section>
            <div className="addresscontinuebutton-Div"><button disabled={loading} onClick={checkValue} className="addresscontinuebutton-css">Continue</button></div>
            {loading ? <div className="CircularProgressBar"><CircularProgress /></div> : <div></div>}
        </section >

    );
}

export default SessionID;
