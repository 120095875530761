import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./selCustomer.css";
import CircularProgress from '@mui/material/CircularProgress';
import InputMask from "react-input-mask";
import * as constants from '../constants/selConstants';
import { Getcustomers, Getsessions } from '../constants/services';

function SelCustomer(props: any) {
    const Navigate = useNavigate();
    const [customerDetails, setCustomerDetails] = useState(
        {
            sessionId: localStorage.getItem("sessionId"),
            firstName: "",
            lastName: "",
            emailAddress: "",
            businessContact: { businessName: "" },
            telephoneNumber: "",
        });
    const token = localStorage.getItem("accesstoken")
    const [loading, setloading] = useState(false);
    const [addressDetails, setAddressDetails] = useState(
        {
            line1: "",
            city: "",
            state: "",
            country: "US",
            zipCode: "",
            zip: "",
        });

    const [errorfield, seterrorfield] = useState(
        {
            errorbusinessName: false,
            errorfirstName: false,
            errorlastName: false,
            erroremailAddress: false,
            errortelephoneNumber: false,
            errorcountry: false,
            errorline1: false,
            errorcity: false,
            errorstate: false,
            errorzipCode: false,
        });
    useEffect(() => {
        async function fetchApi() {
            const token = localStorage.getItem("accesstoken")
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`
                }
            }
            const amdocsId = localStorage.getItem("sessionId");
            const response = await Getsessions({ amdocsId, config })
            if (response) {
                const Data = (response.data && response.data["validateAddressResponseCust"] && response.data["validateAddressResponseCust"].addressDetails ? response.data["validateAddressResponseCust"].addressDetails[0] : {});
                setAddressDetails(prevState => ({
                    ...prevState,
                    line1: Data.addressLine1 ?? "",
                    city: Data.city ?? "",
                    state: Data.state ?? "",
                    zipCode: Data.zipCode ?? "",
                    zip: Data.zipCode ?? ""
                }))
            }
        }
        fetchApi()
    }, []);

    const CheckValue = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setloading(true);
        if (customerDetails.businessContact.businessName === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorbusinessName: true
            }))

        }
        if (addressDetails.country === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorcountry: true,
            }))
        }

        if (customerDetails.firstName === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorfirstName: true,
            }))
        }
        if (customerDetails.lastName === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorlastName: true,
            }))
        }
        if (customerDetails.emailAddress === "") {
            seterrorfield(prevState => ({
                ...prevState,
                erroremailAddress: true,
            }))
        }
        if (customerDetails.telephoneNumber === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errortelephoneNumber: true,
            }))
        }
        if (addressDetails.line1 === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorline1: true,
            }))
        }
        if (addressDetails.state === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorstate: true,
            }))
        }
        if (addressDetails.zipCode === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorzipCode: true,
            }))
        }
        if (addressDetails.city === "") {
            seterrorfield(prevState => ({
                ...prevState,
                errorcity: true,
            }))
        }

        if (customerDetails.telephoneNumber === "" || customerDetails.emailAddress === "" || customerDetails.lastName === "" || customerDetails.firstName === "" || addressDetails.country === "" || customerDetails.businessContact.businessName === "" || addressDetails.line1 === "" || addressDetails.state === "" || addressDetails.zipCode === "" || addressDetails.city === "") {

            setloading(false);
            return null;
        }
        else {
            setloading(true);
            handleFormCLick(e);
        }
    }

    const handleFormCLick = async (e: any) => {
        const response = await Getcustomers({ apiBody, token })
        if (response) {
            setloading(false);
            if (response.status === 200 || response.status === 201) {
                Navigate("/SelProposal", { state: { sessionId: customerDetails.sessionId } });
            }
            else if (response.status === 500) {
                if (response.data.Type === constants.AMDOCS) {
                    Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }
        }
        else {
            Navigate("/Error");
        }
    }

    const apiBody = {
        ...customerDetails,
        "address": addressDetails,
    }

    return (

        <section className="customerdataField">
            <div className="customerDetails-form">
                <Fragment>

                    <h4 className="customer-deatail-heading">Customer Details</h4>
                    <div className="customerHeading-div">
                        <div className="customer-div">
                            <div className="customer-div-label">  First Name  </div>
                            <input value={customerDetails.firstName} className="customerInput-Box"
                                type="text"
                                onChange={(e) => {
                                    setCustomerDetails(prevState => ({
                                        ...prevState,
                                        firstName: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorfirstName: false
                                    }))
                                }}
                                name="firstName"
                            />
                            {errorfield.errorfirstName &&
                                <div className="validationError">First Name is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Last Name
                            </div>
                            <input name="lastName" className="customerInput-Box"
                                value={customerDetails.lastName}
                                type="text"
                                onChange={(e) => {
                                    setCustomerDetails(prevState => ({
                                        ...prevState,
                                        lastName: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorlastName: false
                                    }))
                                }
                                }
                            />
                            {errorfield.errorlastName &&
                                <div className="validationError">Last Name is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Email Address
                            </div>
                            <input name="emailAddress" value={customerDetails.emailAddress}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setCustomerDetails(prevState => ({
                                        ...prevState,
                                        emailAddress: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        erroremailAddress: false
                                    }))
                                }
                                }
                            />
                            {errorfield.erroremailAddress &&
                                <div className="validationError">Email address is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Telephone Number
                            </div>
                            <InputMask value={customerDetails.telephoneNumber} mask="(999) 999-9999"
                                id="phone" className="customerInput-Box"
                                onChange={(e) => {
                                    setCustomerDetails(prevState => ({
                                        ...prevState,
                                        telephoneNumber: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errortelephoneNumber: false
                                    }))

                                }
                                }
                            />
                            {errorfield.errortelephoneNumber &&
                                <div className="validationError">Telephone is required field</div>
                            }
                        </div>
                    </div>

                    <h4 className="customer-deatail-heading">Address</h4>
                    <div className="customerHeading-div">
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Street Address
                            </div>
                            <input
                                disabled={true}
                                // disabled={addressDetails.line1 ? true : false} name="streetAddress1" 
                                value={addressDetails.line1}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setAddressDetails(prevState => ({
                                        ...prevState,
                                        line1: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorline1: false
                                    }))
                                }
                                }
                            />
                            {errorfield.errorline1 &&
                                <div className="validationError">Street address is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                City
                            </div>
                            <input name="city"
                                disabled={true}
                                //  disabled={PropsaddressDetails.city ? true : false}
                                value={addressDetails.city}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setAddressDetails(prevState => ({
                                        ...prevState,
                                        city: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorcity: false
                                    }))
                                }
                                }
                            />
                            {errorfield.errorcity &&
                                <div className="validationError">City address is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                State
                            </div>
                            <input
                                //  disabled={PropsaddressDetails.state ? true : false} 
                                disabled={true}
                                name="state" value={addressDetails.state}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setAddressDetails(prevState => ({
                                        ...prevState,
                                        state: e.target.value
                                    }))
                                }
                                }
                            />
                            {errorfield.errorstate &&
                                <div className="validationError">State address is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Country
                            </div>
                            <input name="country" value={addressDetails.country}
                                disabled={true}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setAddressDetails(prevState => ({
                                        ...prevState,
                                        country: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorcountry: false
                                    }))
                                }
                                }
                            />
                            {errorfield.errorcountry &&
                                <div className="validationError">Country is required field</div>
                            }
                        </div>
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Zip Code
                            </div>
                            <input
                                // disabled={PropsaddressDetails.zipCode ? true : false} 
                                name="zipCode" value={addressDetails.zipCode}
                                disabled={true}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setAddressDetails(prevState => ({
                                        ...prevState,
                                        zipCode: e.target.value
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorzipCode: false
                                    }))
                                }
                                }
                            />
                            {errorfield.errorzipCode &&
                                <div className="validationError">zip Code address is required field</div>
                            }
                        </div>
                    </div>
                    <h4 className="customer-deatail-heading">Business Contact</h4>
                    <div className="customerHeading-div">
                        <div className="customer-div">
                            <div className="customer-div-label">
                                Business Name
                            </div>
                            <input name="businessName" value={customerDetails.businessContact.businessName}
                                type="text" className="customerInput-Box"
                                onChange={(e) => {
                                    setCustomerDetails(prevState => ({
                                        ...prevState,
                                        businessContact: { businessName: e.target.value }
                                    }));
                                    seterrorfield(prevState => ({
                                        ...prevState,
                                        errorbusinessName: false
                                    }))
                                }
                                }
                            />
                            {errorfield.errorbusinessName &&
                                <div className="validationError">Business Name is required field</div>
                            }
                        </div>
                    </div>

                </Fragment>
            </div>
            <div className="customercontinuebutton-Div"><button onClick={CheckValue} className="customercontinuebutton-css">Continue</button></div>
            {loading ? <div className="CircularProgressBar"><CircularProgress /></div> : <div></div>}
        </section>

    );
}

export default SelCustomer;

