import React, { useState } from "react";
import './Header.css';
import PersonIcon from '@mui/icons-material/Person';
import { Getsessions } from "../../constants/services";
const fetchSessionDetails = async (amdocsId: any) => {

    var copyText = document.getElementById("sessionID");
    if (copyText) {
        navigator.clipboard.writeText(copyText.innerText);
        alert("Copied the text: " + copyText.innerText);
    }
    const token = localStorage.getItem("accesstoken")


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`
        }
    }

    const response = await Getsessions({ amdocsId, config })
    if (response) {
        console.clear();
        console.log('Session Data:\n', response.data);
    }
}

function Header(props: any) {
    const [dropdown, setShowdropdown] = useState(false);
    const handleDropdown = () => {
        setShowdropdown(!dropdown)
    }
    return (
        <section className="header">
            <section >
                <div className="comcast-text">COMCAST BUSINESS</div>
            </section>


            <section className="comcast-black-text">
                <PersonIcon style={{
                    cursor: "pointer",
                    fontSize: " 28px"
                }} onClick={handleDropdown} />
                {dropdown ?
                    <ul className="header-dropdown-menu">
                        <li className="comcast-mouseover" onClick={() => { fetchSessionDetails(props.amdocsId ? props.amdocsId : ""); setShowdropdown(false); }}>Session ID:
                            <span style={{ marginLeft: "10px" }} id="sessionID">{props.amdocsId ? props.amdocsId : ""}
                            </span>
                        </li>
                    </ul>
                    : ""
                }

            </section>
        </section >
    );
}

export default Header;
