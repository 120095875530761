import React, { useEffect, useState } from "react";
import "./App.css";
import { acquireAccessToken } from "./utils/token";
import { msalInstance } from "./index";
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import SessionID from './pages/SessionID/SessionID';
import SelCustomer from './pages/selCustomer';
import SelProposal from './pages/selProposal';
import SelOffer from './pages/selOffer';
import SelConfigure from './pages/selConfigure';
import Error from './component/Error/Error';
import { Route, Routes } from "react-router-dom";
import Selorderconfirm from "./pages/selOrderconfirm";
import Loading from './component/Loading/Loading';
import SelCheckout from './pages/SelCheckout/SelCheckout';
import SelorderDetails from './pages/selorderDetails';
import OrderComplete from "./pages/orderComplete";
import { GetverifyAgentProfile } from "./constants/services";

function App() {

  const [sessionId, setsessionId] = useState<any>(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const verifyAgentProfile = {
      "affiliateId": "1",
      "channelName": "test",
      "agentId": 1,
      "isKiosk": true,
      "subAffiliated": 4,
      "storeId": 2
    };
    setLoading(true);
    let token = "";
    async function fetchApi() {
      await acquireAccessToken(msalInstance).then((t) => {
        if (t) {
          token = t;
          localStorage.setItem("accesstoken", t);
        }
      });

      const apiBody = JSON.stringify(verifyAgentProfile);
      const response = await GetverifyAgentProfile({ apiBody, token })
      if (response) {
        if (response.status === 200 || response.status === 201) {
          setsessionId(response.data.sessionId)
          localStorage.setItem("sessionId", response.data.sessionId);
        }
      }
      setLoading(false);
    }
    fetchApi()
  }, []);

  return (
    <div className="App">

      <Header amdocsId={sessionId} />
      <section className="BodySection">

        <Routes>
          <Route path="/" element={loading ? <Loading text="Please wait... We are authenticating you" /> : <SessionID />} />
          <Route path="/SelCustomer" element={<SelCustomer />} />
          <Route path="/Error" element={<Error />} />
          <Route path="/SelProposal" element={<SelProposal />} />
          <Route path="/SelOffer" element={<SelOffer />} />
          <Route path="/SelConfigure" element={<SelConfigure />} />
          <Route path="/SelCheckout" element={<SelCheckout />} />
          <Route path="/Selorderconfirm" element={<Selorderconfirm />} />
          <Route path="/SelorderDetails" element={<SelorderDetails />} />
          <Route path="/orderComplete" element={<OrderComplete />} />

        </Routes>
      </section>
      <Footer />
    </div>
  );
}

export default App;
