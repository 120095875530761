declare const window: any;

export const configuration: Configuration = {
  ENVIRONMENT: window.config.environment,
  AZURE_CLIENT_ID: window.config.azureClientId,
  AZURE_TENANT: window.config.azureTenant,
  PLATFORM_SCOPE: window.config.platformScope,
  API: {
    HOST: window.config.apiHost,
  },

  POLL_WAIT_TIME_MILLI_SECONDS: '10000',
};

type Configuration = {
  ENVIRONMENT: string;
  AZURE_CLIENT_ID: string;
  AZURE_TENANT: string;
  PLATFORM_SCOPE: string;
  API: any;
  POLL_WAIT_TIME_MILLI_SECONDS: any,
};
