import React, { useEffect } from "react";
import "./selProposal.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import * as constants from '../constants/selConstants';
import { Getcontent, Getproposal, Getpresenteligibleoffers } from '../constants/services';
function SelProposal() {
    const Navigate = useNavigate();
    const sessionId = localStorage.getItem("sessionId");
    useEffect(() => {
        const token = localStorage.getItem("accesstoken")
        const createProposal = async () => {
            const apiBody = {
                "sessionId": sessionId
            }

            const response = await Getproposal({ apiBody, token })
            if (response) {
                if (response.status === 200 || response.status === 201) {
                    presentEligibleOffers();
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }

        }
        const presentEligibleOffers = async () => {

            const apiBody = {
                "sessionId": sessionId
            }
            const response = await Getpresenteligibleoffers({ apiBody, token })
            if (response) {
                if (response.data) {
                    if (response.data != null && response.data.shoppingOffer != null) {
                        getContent(response.data.shoppingOffer);
                    }
                }
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
                else if (response.status === 500) {
                    if (response.data.Type === constants.AMDOCS) {
                        Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                    }
                    else {
                        Navigate("/Error");
                    }
                }
                else {
                    Navigate("/Error");
                }
            }
            else {
                Navigate("/Error");
            }

        }
        const getContent = async (offers: any[]) => {
            var apiBody = {
                affiliateId: "DOT_COM_COMMERCIAL",
                channelName: "WEB",
                ids: {
                    string: offers.map((offer) => "OFFER:" + offer.offerId),
                    stringLength: offers.length
                }
            }
            const response = await Getcontent({ apiBody, token })
            try {
                if (response) {
                    if (response.data) {
                        console.log(response.data);
                        Navigate("/selOffer", { state: { shoppingOffer: offers, content: response.data.presentationElements.presentationElement } });
                    }
                    else if (response.status === 500) {
                        if (response.data.Type === constants.AMDOCS) {
                            Navigate("/Error", { state: { ErrorMessage: constants.AMDOCS_ERROR } });
                        }
                        else {
                            Navigate("/Error");
                        }
                    }
                }
                else {
                    Navigate("/Error");
                }

            }
            catch (ex) {
                Navigate("/Error");
            }
        }
        createProposal();
    }, [Navigate, sessionId]);
    return (
        <section className="successSessionID-section">
            <div className="success-form">
                <div className="selorder-loading-message">
                    {constants.CREATE_PROPOSAL}
                </div>
                <div className="CircularProgressBar"><CircularProgress /></div>
            </div>
        </section>
    );
}

export default SelProposal;