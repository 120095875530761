import React from "react";
import './Loading.css';
import CircularProgress from '@mui/material/CircularProgress';
function Loading(props: any) {
    return (
        <section className="loading-screen">
            <div className="loading-screen-message-div">
                <span className="loading-screen-message">{props.text ? props.text : "Please wait... "}</span>
            </div>
            <CircularProgress />
        </section>

    );
}

export default Loading;
